<template>
    <div>
		<div v-if="loading">
            <Spinner />
        </div>
		<div v-else>
			<CustomTable 
				id_table="historique_syndic_part"
				primaryKey="part_id"
				:items="history"
			>
				
			</CustomTable>
		</div>

        <b-modal ref="modal" hide-footer>
            <template v-slot:modal-title>
                {{ $t("action.modifier_date") }}
            </template>
            <div>
            	<label for="date">{{ $t('syndication.date_cession') }}</label>
				<e-datepicker id="date" v-model="date_cession"></e-datepicker>
				<div class="text-center mt-4">
					<b-button variant="primary" @click.prevent="saveDateCession">
						<font-awesome-icon v-if="processing_date" :icon="['fas', 'spinner']" pulse />
						<font-awesome-icon v-else :icon="['fal', 'save']"/> {{ $t("global.sauvegarder") }}
					</b-button>
				</div>
            </div>
        </b-modal>
    </div>
</template>

<script type="text/javascript">
    import Syndic from "@/mixins/Syndic.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
        name: "HistorySyndicShutter",
        mixins: [Syndic, Navigation],
        props: {
            syndicpart_id: { type: Number, default: 0 }
        },
        data() {
			return {
				loading: false,
				history: [],
                events_tab: {
                    'TableAction::goToEditDateCession': this.openEditCessionModal
                },
                part_to_edit: null,
                date_cession: new Date(),
                processing_date: false
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			preinit_component() {
				this.loadHistoriqueSyndicPart(this.syndicpart_id)
					.then(res => this.history = res)
			},
			openEditCessionModal(part) {
				if(!part.part_end) {
					this.failureToast(this.getTrad("toast.syndic_part_without_cession"))
					return false
				}
				this.part_to_edit = part.part_id
				this.date_cession = new Date.parseTz(part.part_end_vanilla)
				this.$refs.modal.show()
			},
			async saveDateCession() {
				this.processing_date = true
				await this.editDateCessionPart(this.part_to_edit, this.date_cession)
				this.processing_date = false
				this.successToast("toast.info_modif_succes")
				this.$refs.modal.hide()
				this.preinit_component()
			}
		},
		components: {
            CustomTable: () => import("GroomyRoot/components/Table/CustomTable")
		}
	}
</script>